import React from "react"
import style from "./hero.mod.scss"
import BorderWrapper from "../border-wrapper/border-wrapper"
import PanelRow from "../panel-row/panel-row"
import Container from "../container/container"
import Button from "../button/button"
import Breadcrumbs from "../global/breadcrumbs"
import Navigator from "../global/navigator"

const Hero = ({
  heading,
  subHeading,
  text,
  imageLeft,
  imageRight,
  breadcrumbs,
  ctas,
  links
}) => {
  return (
    <Container>
      {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
      <BorderWrapper topColor="light" bottomColor="light" isHero>
        <PanelRow
          panels={[
            { image: imageRight, order: 2, className: style.hero__image },
            {
              image: imageLeft,
              order: 1,
              bgColor: "primary",
              opacity: 8,
              content: (
                // <Container pt={4} pb={4} pl={3} pr={3}>
                <div className={style.hero}>
                  {subHeading && <p className={style.hero__subheading}>{subHeading}</p>}
                  <h1 className={style.hero__heading}>{heading}</h1>
                  <div className={style.hero__text}>{text}</div>
                  <div className={style.hero__ctas}>
                    {ctas && ctas.map((cta, i) => {
                      if (!cta || !cta.link) { return null; }
                      return (
                        <Button secondary recordId={cta.link.originalId} key={`cta${i}`}>{cta.text}</Button>
                      );
                    })}
                  </div>
                  <div className={style.hero__links}>
                    {links && links.map((link, i) => {
                      if (!link || !link.link) { return null; }
                      return (
                        <Navigator recordId={link.link.originalId} key={`link${i}`}>{link.text}</Navigator>
                      );
                    })}
                  </div>
                </div>
                // </Container>
              ),
            },
          ]}
        />
      </BorderWrapper>
    </Container>
  )
}

export default Hero
