import React from "react"
import style from "./lead.mod.scss"

const Lead = ({ text, heading, icon, isHome }) => {
  let classes = [style.lead]
  isHome && classes.push(`${style.lead}--home`)

  return (
    <div className={[...classes].join(" ")}>
      {icon && <div className={style.lead__icon}>{icon}</div>}
      {heading && <h2>{heading}</h2>}
      {text && <p>{text}</p>}
    </div>
  )
}

export default Lead
