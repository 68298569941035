import React, { useState } from "react"
import ProductSlider from "./product-slider"
import Button from "../button/button"
import Container from "../container/container"
import cn from "classnames"
import style from "./product-slider.mod.scss"

const CustomUrlSlider = ({ heading, subHeading, tabs, allProducts, regionChildNodes }) => {
  
  const [currentTabId, setCurrentTabId] = useState(tabs ? tabs[0].originalId : null)
  
  const limit = 10
  
  const getProducts = (tab) => {
    if (typeof tab !== "undefined" && allProducts) {
      // filter products
      let c = tab.collection
      if (c.internal?.type === "DatoCmsCustomUrl") {
        let urlGrapes = c.urlBuilder.filter(
          (item) => item.internal.type === "DatoCmsGrapeUrl"
        )
        let urlTypes = c.urlBuilder.filter(
          (item) => item.internal.type === "DatoCmsWineTypeUrl"
        )
        let urlChildRegions = c.urlBuilder.filter(
          (item) => item.internal.type === "DatoCmsChildRegionUrl"
        )
        let urlParentRegion = c.urlBuilder.filter(
          (item) => item.internal.type === "DatoCmsParentRegionUrl"
        )
        let urlWinery = c.urlBuilder.filter(
          (item) => item.internal.type === "DatoCmsWineryUrl"
        )
        let urlPrice = c.urlBuilder.filter(
          (item) => item.internal.type === "DatoCmsPriceUrl"
        )
        let hasParentRegion = urlParentRegion.length > 0 ? true : false
        let childRegionIds = []
        if (hasParentRegion) {
          const parentChildRegions = regionChildNodes.filter(
            (item) => item.parentRegion.id === urlParentRegion[0].link.id
          )
          childRegionIds = parentChildRegions.map((item) => item.portalRegionId)
        }
        let filteredProducts = allProducts.filter((wine) => {
          let result = true
          // filter grape
          if (urlGrapes.length > 0) {
            if (wine.grape[0].id !== urlGrapes[0].link.portalId) {
              result = false
            }
          }
          // filter category
          if (result && urlTypes.length > 0) {
            if (wine.category !== urlTypes[0].link.title) {
              result = false
            }
          }
          // filter winery
          if (result && urlWinery.length > 0) {
            if (wine.winery.id.toString() !== urlWinery[0].portalId.toString()) {
              result = false
            }
          }
          // filter child regions
          if (result && urlChildRegions.length > 0) {
            if (wine.winery.region_id !== urlChildRegions[0].link.portalId) {
              result = false
            }
          }
          // filter parent regions
          if (result && hasParentRegion) {
            if (!childRegionIds.includes(wine.winery.region_id)) {
              result = false
            }
          }
          // filter price
          if (result && urlPrice.length > 0) {
            for (let i=0; i<urlPrice.length; i++) {
              let p = urlPrice[i]
              if (p.minMax === 'min' && p.price > wine.price) {
                result = false
                break
              }
              if (p.minMax === 'max' && p.price < wine.price) {
                result = false
                break
              }
            }
          }
          return result
        })
        return filteredProducts.slice(0, limit)
      }
      if (c.internal.type === "DatoCmsPriceRange") {
        let filteredProducts = allProducts.filter((wine) => {
          let result = true
          if (c.minPrice && wine.price < c.minPrice) {
            result = false
          }
          if (result && c.maxPrice && c.maxPrice < 1000 && wine.price > c.maxPrice) {
            result = false
          }
          return result
        })
        return filteredProducts.slice(0, limit)
      }
      return []
    }
    return []
  }
   
  return (
    <div>
      <Container gutters width={"large"}>
        {subHeading && <h5 className={style.productSlider__subheading}>{subHeading}</h5>}
        {heading && <h4 className={style.productSlider__heading}>{heading}</h4>}
        <div className={style.productSlider__tabs}>
          {tabs && tabs.map((tab) => {
            return (
              <button
                type="button"
                onClick={() => {setCurrentTabId(tab.originalId)}}
                className={currentTabId === tab.originalId ? style.productSlider__activetab : ""}
                key={`tab${tab.originalId}`}
              >
                {tab.tabText}
              </button>
            )
          })}
        </div>
      </Container>
      
      {tabs && tabs.map((tab) => {
        let products = getProducts(tab)
        if (!products || products.length === 0) {
          return null
        }
        return (
          <div className={cn(style.productSlider__tabwrapper, {[style.productSlider__activetabwrapper]: tab.originalId === currentTabId})} key={tab.originalId}>
            <ProductSlider items={products} />
            {tab.ctaText && <Container gutters pt={1} width={"large"} textAlign="center">
              <Button recordId={tab.collection.id}>{tab.ctaText}</Button>
            </Container>}
          </div>
        )
      })}
    </div>
  )
}

export default CustomUrlSlider
