import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import Hero from "../components/hero/hero"
import Pathways from "../components/pathways/pathways"
import ContentImgText from "../components/content-img-text/content-img-text"
import Lead from "../components/global/lead"
import GlassIcon from "../components/icons/glass-icon"
import WineryFeatured from "../components/winery/winery-featured"
import ProductSlider from "../components/product/product-slider"
import CustomUrlSlider from "../components/product/custom-url-slider"
import ReviewsCarousel from "../components/reviews/reviews-carousel"
import { InView } from "react-intersection-observer"

const Homepage = ({ data }) => {
  const { featured, datoCmsHomepage, recommended, allPortalWine, allDatoCmsRegionChild } = data
  const {
    heroClearImage,
    heroCoveredImage,
    heroCtas,
    heroAdditionalLinks,
    heading,
    subHeading,
    text,
    pathways,
    contentBlocks,
    featuredWinery,
    customUrlSlider,
    customUrlSliderHeading,
    customUrlSliderSubHeading,
    introText,
    introHeading,
    seoMetaTags,
  } = datoCmsHomepage

  return (
    <Layout seo={seoMetaTags}>
      <Container bg={"light"}>
        <Hero
          imageLeft={heroCoveredImage}
          imageRight={heroClearImage}
          heading={heading}
          subHeading={subHeading}
          text={text}
          ctas={heroCtas}
          links={heroAdditionalLinks}
        />
      </Container>
      <Container gutters bg={"light"} pt={1} pb={2} width={"small"}>
        <Lead
          text={introText}
          heading={introHeading}
          isHome
          icon={<GlassIcon />}
        />
      </Container>
      <Container pt={2} pb={3} bg={"light"} width={"none"}>
        <ProductSlider
          heading={"Featured Wines"}
          subHeading={"Our wines"}
          items={featured.nodes}
          cta={{text: "Shop Wines", customPath: "/wine"}}
        />
      </Container>
      <Pathways data={pathways} bottomColor="white" />
      <Container pt={2} pb={2} gutters width={"large"}>
        {contentBlocks.map((block) => {
          return (
            <Container pb={2}>
              <ContentImgText key={block.id} {...block} />
            </Container>
          )
        })}
      </Container>
      <Container pt={2} pb={2} bg={"light"} width={"none"}>
        <ProductSlider
          heading={"Highly Recommended"}
          subHeading={"Our wines"}
          items={recommended.nodes}
        />
      </Container>
      <Container bg="light" pt={2} pb={2} gutters width="large">
        <WineryFeatured {...featuredWinery[0]} />
      </Container>
      <Container bg="light" pt={2} pb={2} gutters width="large">
        <CustomUrlSlider
          tabs={customUrlSlider}
          allProducts={allPortalWine?.nodes}
          regionChildNodes={allDatoCmsRegionChild?.nodes}
          heading={customUrlSliderHeading}
          subHeading={customUrlSliderSubHeading}
        />
      </Container>
      <Container bg="light" pt={2} pb={3} gutters width="large">
        <InView>
          {({ inView, ref, entry }) => (
            <div ref={ref}>
              <ReviewsCarousel inView={inView} />
            </div>
          )}
        </InView>
      </Container>
    </Layout>
  )
}

export default Homepage

export const query = graphql`
  fragment LinkQuery on DatoCmsLink {
    text
    link {
      ... on DatoCmsHomepage {
        originalId
      }
      ... on DatoCmsCollectionParent {
        originalId
      }
      ... on DatoCmsRegionsPage {
        originalId
      }
      ... on DatoCmsRegionParent {
        originalId
      }
      ... on DatoCmsNewsPage {
        originalId
      }
      ... on DatoCmsPost {
        originalId
      }
      ... on DatoCmsCategory {
        originalId
      }
      ... on DatoCmsWineriesPage {
        originalId
      }
      ... on DatoCmsGrape {
        originalId
      }
      ... on DatoCmsWine {
        originalId
      }
      ... on DatoCmsPairing {
        originalId
      }
      ... on DatoCmsPriceRange {
        originalId
      }
      ... on DatoCmsCellar {
        originalId
      }
      ... on DatoCmsCustomUrl {
        originalId
      }
    }
  },
  {
    datoCmsHomepage {
      title
      heading
      subHeading
      text
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      introHeading
      introText
      heroClearImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "960", fit: "crop", w: "1000", q: 60 }
        )
        alt
      }
      heroCoveredImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "960", fit: "crop", w: "1000", q: 60 }
        )
        alt
      }
      heroCtas {
        ...LinkQuery
      }
      heroAdditionalLinks {
        ...LinkQuery
      }
      pathways {
        ...Pathways
      }
      contentBlocks {
        ...ContentBlockQuery
      }
      featuredWinery {
        title
        subHeading
        locationOverride
        content
        portalId
        buttonText
        ctaText
        ctaLink {
          ... on DatoCmsHomepage {
            originalId
          }
          ... on DatoCmsCollectionParent {
            originalId
          }
          ... on DatoCmsCellar {
            originalId
          }
          ... on DatoCmsPairing {
            originalId
          }
          ... on DatoCmsWine {
            originalId
          }
          ... on DatoCmsGrape {
            originalId
          }
          ... on DatoCmsRegionsPage {
            originalId
          }
          ... on DatoCmsWineriesPage {
            originalId
          }
        }
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { h: "600", fit: "crop", w: "820", q: 60 }
          )
          alt
        }
      }
      customUrlSliderHeading
      customUrlSliderSubHeading
      customUrlSlider {
        originalId
        tabText
        ctaText
        collection {
          ... on DatoCmsPriceRange {
            id: originalId
            internal {
              type
            }
            minPrice
            maxPrice
          }
          ... on DatoCmsCustomUrl {
            id: originalId
            internal {
              type
            }
            urlBuilder {
              ... on DatoCmsWineTypeUrl {
                internal {
                  type
                }
                link {
                  title
                  color
                }
              }
              ... on DatoCmsGrapeUrl {
                internal {
                  type
                }
                link: wineType {
                  portalId
                }
              }
              ... on DatoCmsParentRegionUrl {
                internal {
                  type
                }
                link {
                  id: originalId
                }
              }
              ... on DatoCmsChildRegionUrl {
                internal {
                  type
                }
                link {
                  portalRegionId
                }
              }
              ... on DatoCmsWineryUrl {
                portalId
                internal {
                  type
                }
              }
              ... on DatoCmsPriceUrl {
                internal {
                  type
                }
                price
                minMax
              }
            }
          }
        }
      }
    }
    featured: allPortalWine(
      filter: { wine_tags: { elemMatch: { name: { eq: "Featured Wines" } } } }
    ) {
      nodes {
        id
        name
        price
        rrp
        discounted_price
        image
        winery {
          id
          minimum_wines_per_order
        }
      }
    }
    recommended: allPortalWine(
      filter: { wine_tags: { elemMatch: { name: { eq: "Highly Recommend" } } } }
    ) {
      nodes {
        id
        name
        price
        rrp
        discounted_price
        image
        winery {
          id
          minimum_wines_per_order
        }
      }
    }
    allPortalWine {
      nodes {
        category
        id
        name
        price
        image
        shiiping_message
        status
        food_pairing {
          id
          name
        }
        wine_type
        year
        wine_tags {
          id
          name
        }
        grape {
          name
          id
        }
        winery {
          name
          region_id
          minimum_wines_per_order
          id
          region {
            name
            id
          }
        }
      }
    }
    allDatoCmsRegionChild {
      nodes {
        id: originalId
        slug
        portalRegionId
        parentRegion {
          slug
          id: originalId
        }
      }
    }
  }
`
