import React from "react"
import style from "./feature.mod.scss"
import Container from "../container/container"
import PanelRow from "../panel-row/panel-row"
import Button from "../button/button"
import Pin from "../icons/pin"

const WineryFeatured = ({
  title,
  subHeading,
  locationOverride,
  content,
  portalId,
  buttonText,
  ctaText,
  ctaLink,
  featureImage,
  portalImage,
}) => {
  return (
    <div className={style.feature}>
      <PanelRow
        panels={[
          {image: featureImage || portalImage},
          {bgColor: "dark-grey", content: (
            <Container pt={2} pb={2} pl={2} pr={2}>
              <p className={style.feature__subheading}>{subHeading}</p>
              <h4 className={style.feature__heading}>{title}</h4>
              <blockquote>
                <div dangerouslySetInnerHTML={{__html: content}} />
                {locationOverride && <p className={style.feature__location}><Pin /><b>{locationOverride}</b></p>}
              </blockquote>
              <div className={style.feature__links}>
                <Button recordId={`winery-${portalId}`} secondary>{buttonText}</Button>
                {(ctaText && ctaLink) && <Button light recordId={ctaLink.originalId}>{ctaText}</Button>}
              </div>
            </Container>
          )}
        ]}
      />
    </div>
  )
}

export default WineryFeatured
