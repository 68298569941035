import React from "react"

const Pin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.34 44.02">
      <path
        d="M14.67 0C6.97 0 0 6.24 0 13.94 0 25.4 11.7 26.6 14.67 44.02c2.98-17.42 14.67-18.63 14.67-30.08C29.34 6.24 22.37 0 14.67 0zm0 20.17a5.5 5.5 0 1 1 5.5-5.5 5.5 5.5 0 0 1-5.5 5.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Pin
