import React, { useState, useEffect } from "react"
import Container from "../container/container"

const ReviewsCarousel = ({ inView = true }) => {
  const [loaded, setLoading] = useState(false)
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)

  useEffect(() => {
    if (window.yotpo && !loaded && inView) {
      window.yotpo.initWidgets()
      setLoading(true)
    }
  }, [inView, loaded])

  return (
    <Container width={"large"} gutters>
      <div>
        <div
          ref={ref}
          className="yotpo yotpo-reviews-carousel"
          data-header-customisation-enabled="1"
          data-header-customisation-text="iHeartWine Reviews"
          data-header-customisation-font-size="18"
          data-header-customisation-alignment="left"
          data-header-customisation-color="#000000"
          data-background-color="transparent"
          data-mode="manual"
          data-type="both"
          data-review-ids="396766596,401697096,396778287,396750402,401696933,401682823,401698040,401753969,396755188"
          data-show-bottomline="1"
          data-autoplay-enabled="1"
          data-autoplay-speed="3000"
          data-show-navigation="1"
        >
          &nbsp;
        </div>
      </div>
    </Container>
  )
}

export default ReviewsCarousel
