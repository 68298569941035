import React from "react"

const GlassIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.74 59.547">
      <path
        data-name="Path 12797"
        d="M19.186 58.627C10.337 55.402 3.623 49.866.924 40.452-.899 34.093.367 27.703 1.382 21.4A89.873 89.873 0 0 1 7.104 1.85c.32-.785.449-2.237 1.775-1.754 1.474.536.31 1.593.043 2.336a92.842 92.842 0 0 0-5.929 29.3c-.309 12.609 7.762 22.9 20.414 23.661a25.934 25.934 0 0 0 14.723-3.335c6.57-3.751 10-10.875 9.878-18.5a93.041 93.041 0 0 0-6-30.894c-.3-.808-1.3-1.983-.137-2.494 1.452-.636 1.593 1.015 1.949 1.908a90.1 90.1 0 0 1 6.87 32.039c.855 12.307-8.9 20.607-18.031 24.227a22.485 22.485 0 0 1-13.47.283"
        fill="#161615"
      />
      <path
        data-name="Path 12798"
        d="M24.934 25.253a27.7 27.7 0 0 1 6.168-7.99c3.371-2.759 6.34-2.456 8.987.95a15.84 15.84 0 0 1 3.45 9.091 24.9 24.9 0 0 1-9.1 21.032 9.768 9.768 0 0 1-5.934 2.727 9.891 9.891 0 0 0 1.668-6.35 57.439 57.439 0 0 0-5.176-18.635l-.063-.825"
        fill="#8a2d60"
      />
      <path
        data-name="Path 12799"
        d="m24.934 25.253.063.825a35.184 35.184 0 0 0-5.257 17.069c-.139 2.753 0 5.405 2.3 7.386A18.546 18.546 0 0 1 7.552 34.121 33.067 33.067 0 0 1 9.38 20.427c1.82-5.236 5.93-6.228 10.135-2.61 2.4 2.066 3.592 4.972 5.414 7.436"
        fill="#8a2d60"
      />
      <path
        data-name="Path 12800"
        d="M22.047 50.533c-2.306-1.981-2.443-4.633-2.3-7.386a35.182 35.182 0 0 1 5.257-17.069 57.441 57.441 0 0 1 5.176 18.635 9.892 9.892 0 0 1-1.668 6.35c-2.24.88-4.359.284-6.461-.53"
        fill="#591934"
      />
      <path
        data-name="Path 12801"
        d="M16.924 13.392a2.84 2.84 0 0 1-2.68-3.007c-.062-1.013.322-1.987 1.64-1.841a3.157 3.157 0 0 1 3.045 2.622c.215 1.38-.934 1.934-2.005 2.226"
        fill="#8c3163"
      />
    </svg>
  )
}

export default GlassIcon
